import React, { useEffect } from "react";
import fileSaver from "file-saver";
import domtoimage from "dom-to-image";
import { theme } from "@classdojo/web/nessie";
import { Button } from "@src/components/nessie-web";
import Ecard from "@src/components/partials/thankyouteachers/Ecard";
import { Box, Flex } from "@src/components/Boxes";
import { Text } from "@src/components/Text";
import { useLocation } from "@reach/router";
import SVGCard from "@src/components/partials/thankyouteachers/SVGCard";
import { getSelectedBackground } from "@src/components/partials/thankyouteachers/backgroundSelector";
import styled from "@emotion/styled";
import SEO from "../components/SEO";
import { logEvent } from "@src/utils/logClient";

const Wrapper = styled(Box)`
  padding: 0;
  height: auto !important;
`;

const ShareMessage = ({ children }) => (
  <Text
    marginTop="20px"
    fontSize="18px"
    lineHeight="22px"
    fontWeight="700"
    color={`${theme.colors.taro90}`}
    textAlign="center"
    marginBottom="10px"
  >
    {children}
  </Text>
);

const ShareButton = ({ handleClick }) => (
  <Box margin={10} width={["100%", "auto"]}>
    <Button kind="primary" onClick={() => handleClick()} href>
      Share
    </Button>
  </Box>
);

const DownloadButton = ({ handleClick }) => (
  <Box margin={10} width={["100%", "auto"]}>
    <Button kind="primary" onClick={() => handleClick()} href>
      Save card
    </Button>
  </Box>
);

const CopyLinkButton = ({ handleClick, label }) => (
  <Box margin={10} width={["100%", "auto"]}>
    <Button kind="primary" onClick={() => handleClick()} href>
      {label}
    </Button>
  </Box>
);

const BackButton = () => (
  <Button kind="tertiary" href="/thankyouteachers">
    Make your own!
  </Button>
);

const LoadingOverlay = ({ visible }) => (
  <div
    style={{
      transform: `translateY(${visible ? "0" : "-100%"})`,
      transition: "transform 0.3s ease",
      position: "fixed",
      top: "0",
      bottom: "0",
      left: "0",
      right: "0",
      zIndex: 9999,
      backgroundColor: "white",
      display: "block",
    }}
  >
    <Flex
      alignItems="center"
      justifyContent="center"
      style={{
        height: "100vh",
        width: "100%",
      }}
    >
      <Text
        color={theme.colors.taro90}
        fontSize="36px"
        lineHeight="1"
        marginBottom="0"
        fontWeight="700"
        textAlign="center"
      >
        Loading card...
      </Text>
    </Flex>
  </div>
);

const LinkContainer = ({ url }) => (
  <Box backgroundColor="white" borderRadius="42px" padding="10px 30px" maxWidth="100%" margin="10px auto">
    <Text color={theme.colors.taro90} fontSize="18px" margin="0" fontWeight="600">
      {url}
    </Text>
  </Box>
);

const ErrorMessage = () => (
  <Flex flexDirection="column" alignItems="center" justifyContent="center" padding="10px" height="100%" width="100%">
    <Text fontSize="36px" fontWeight="700" marginBottom="20px" textAlign="center">
      Invalid card link, try making your own
    </Text>
    <BackButton />
  </Flex>
);

const handleDownloadButton = (node, handleLoading, handleDownloadMode) => {
  if (!document) return;
  handleLoading(true);
  setTimeout(() => {
    handleDownloadMode(true);
    domtoimage.toBlob(node).then((blob) => {
      fileSaver.saveAs(blob, "thankyouCard.png");
      handleDownloadMode(false);
      handleLoading(false);
    });
  }, 500);
};

const handleShareButton = (url) => {
  navigator
    .share({
      title: "You received a classdojo thanks card",
      url: url,
    })
    .then(() => console.log("Card shared!"));
};

const copyToClipboard = (inputRef, copyText, handleBtnLabel) => {
  if (navigator.clipboard) {
    navigator.clipboard.writeText(copyText).then(
      () => {
        handleBtnLabel("Copied!");
        setTimeout(() => handleBtnLabel("Copy link"), 500);
      },
      (error) => {
        console.log(error);
      },
    );
  } else {
    inputRef.current.select();
    console.log(document.execCommand("copy")); //true
    document.execCommand("copy");
    handleBtnLabel("Copied!");
    setTimeout(() => handleBtnLabel("Copy link"), 500);
  }
};

const EcardPage = () => {
  const cardElement = React.useRef(null);
  const urlElement = React.useRef(null);
  const [loading, setLoading] = React.useState(true);
  const [downloadMode, setDownloadMode] = React.useState(false);
  const [copyMessage, setCopyMessage] = React.useState("Copy link");
  const [cardData, setCardData] = React.useState({
    template: 0,
    recipient: "",
    sender: "",
    message: "",
  });
  const location = useLocation();

  React.useEffect(() => {
    logEvent({
      eventName: "web.external_page.ecard.card-loaded",
      eventValue: window.location.href,
    });
    if (!location.search) {
      return setLoading(false);
    }

    const cardCode = location.href.split("=")[1];
    fetch(`/api/teachercard?code=${cardCode}`, {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    })
      .then((res) => res.json())
      .then((data) => {
        setCardData(data);
        setLoading(false);
      });
  }, []);

  if (!loading && location.search == "") {
    return <ErrorMessage />;
  } else {
    {
      /*const navigatorSupportsSharing = checkForShareAPi();*/
    }
    const cardUrl = location.href;
    const background = cardData.template;
    const teacherName = cardData.recipient;
    const senderName = cardData.sender;
    const text = cardData.message;
    const backgroundCssValue = getSelectedBackground(background);
    const tweetString =
      "https://twitter.com/compose/tweet?text=" +
      encodeURIComponent("Thank you for all you do! 🙌 #ClassDojoGivesThanks ") +
      encodeURIComponent(cardUrl);

    return (
      <Wrapper padding={0} marginTop={-10} marginBottom={-10}>
        <SEO
          title="Thank you teachers!"
          description="We are grateful for all your hard work and dedication in 2020"
          image="https://static.classdojo.com/img/thankyou-card-2020.png"
          twitter={{
            card: "summary_large_image",
            site: "@classdojo",
            creator: "@classdojo",
            image: "https://static.classdojo.com/img/thankyou-card-2020.png",
          }}
        />
        <LoadingOverlay visible={loading} />
        <Flex
          flexDirection="column"
          alignItems="center"
          width="100vw"
          padding={["20px", "40px"]}
          minHeight="calc(100vh - 297px)"
          justifyContent="center"
          css={{
            backgroundImage: backgroundCssValue,
            backgroundPosition: "top center",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
          }}
        >
          <Ecard
            background={backgroundCssValue}
            teacherName={teacherName}
            senderName={senderName}
            text={text}
            showBackground={false}
          />
          <Box
            backgroundColor="white"
            height={downloadMode ? "1080px" : "auto"}
            ref={cardElement}
            marginBottom={["30px", "50px"]}
          >
            {downloadMode && (
              <SVGCard
                cardBackground={getSelectedBackground(background, "static")}
                teacherName={teacherName}
                senderName={senderName}
                message={text}
                url="www.classdojo.com/thanks"
              />
            )}
          </Box>
          {/*<LinkContainer url={cardUrl} ref={urlElement} />*/}
          <ShareMessage>
            Grab your thank you card and share in a ClassDojo message or on social media with #ClassDojoGivesThanks{" "}
            <span aria-hidden={true}>🤗</span>
          </ShareMessage>
          <Flex
            marginBottom="20px"
            flexDirection={["column", "row"]}
            flexWrap="wrap"
            justifyContent="center"
            alignItems="center"
          >
            <CopyLinkButton
              label={copyMessage}
              handleClick={() => copyToClipboard(urlElement, cardUrl, setCopyMessage)}
            />
            {/*navigatorSupportsSharing && <ShareButton handleClick={() => handleShareButton(cardUrl)} />*/}
            <DownloadButton
              handleClick={() => handleDownloadButton(cardElement.current, setLoading, setDownloadMode)}
            />
            <Box margin={10} width={["100%", "auto"]}>
              <Button href={tweetString} target="_blank" data-dnt="true">
                Tweet this
              </Button>
            </Box>
            <Box margin={10} width={["100%", "auto"]}>
              <BackButton />
            </Box>
          </Flex>
        </Flex>
      </Wrapper>
    );
  }
};

export default EcardPage;
